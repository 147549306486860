import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Spin } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavPanel from './NavPanel';
import NavSearch  from './NavSearch';
import SearchInput from './NavSearch/SearchInput.js'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import { Alert  } from 'antd';
import { BASEURL,APIBASEURL } from '../../BaseURL';

import { updateBalance,getBalance } from '../../redux/actions/Theme';
import { GETBALANCE } from '../../redux/constants/Theme';

const { Header } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 20, marginTop:'25px' }} spin />;

export const HeaderNav = props => {
  const { balance, updateBalance,getBalance, navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction } = props;
  const [searchActive, setSearchActive] = useState(false)

  const onSearchActive = () => {
    setSearchActive(true)
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = ()=> {
    if(!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if(isNavTop || isMobile) {
      return '0px'
    }
    if(navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {    
    gettopbardata();
    const timer = setInterval(() => {
        gettopbardata()
    }, 60000)
    
    // Stops the old timer when umount the component.
    return function stopTimer() {
      clearInterval(timer)
    }

    // gettopbardata();
    
    // setInterval(() => gettopbardata(), 10000)        
    // if(!isMobile) {
    //   onSearchClose()
    // }   
  },[])



  // const [balance, setbalance] = useState();
  const gettopbardata = async () => {
    await updateBalance();
    // console.log(balance);
    // console.log(props);
    // const balance = localStorage.getItem(GETBALANCE)
    // setbalance(balance);

  //   return;
  //   const myHeaders = new Headers();
  //   const userid = localStorage.getItem('userID')
  //   const token = localStorage.getItem('usertoken'); 
  //   // console.log(token);
  //   myHeaders.append('Content-Type', 'application/json');
  //   myHeaders.append('Authorization', token);           
  //   const res = await fetch(BASEURL+'gettopbardata', {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: JSON.stringify({
  //         userID:userid
  //       })
  //   });

  // const data = await res.json(); 
  // if(data.status == 201){
  //   setbalance(data.data[0].balance);
  // }
  // console.log(data);
  }
  // setInterval(gettopbardata(), 60000);
  

  
  return (
    <Header className={`app-header ${navMode}`} style={{backgroundColor: headerNavColor}}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode}/>
        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">          
              {
                isNavTop && !isMobile ?
                null
                :
                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onToggle()}}>
                  {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                </li>
              }
              {
                isMobile ?
                <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onSearchActive()}}>
                  <SearchOutlined />
                </li>
                :
                <li className="ant-menu-item ant-menu-item-only-child" style={{cursor: 'auto'}}>
                  {/* <SearchInput mode={mode} isMobile={isMobile} /> */}
                </li>
              }
            </ul>

          </div>
          <div className="nav-right">
           {
             balance >= 5 ?
             <Alert message={'Balance: $'+balance} type="success" style={{height: '26px',marginTop: '24px','backgroundColor': '#68e9a2'}}/>
             :null
           } 
           {
             balance < 5 ?
             <Alert message={'Balance: $'+balance} type="error" style={{height: '26px',marginTop: '24px','backgroundColor': '#ff675c'}}/>
             :null
            //  <Spin size="small">
            //  <Alert message={'Balance: Loading..'} type="error" style={{height: '26px',marginTop: '24px','backgroundColor': '#ff675c'}}/>
            //  </Spin>
           } 

            {
             !balance &&
             <Spin size="small">
             <Alert message={'Balance: Loading..'} type="error" style={{height: '26px',marginTop: '24px','backgroundColor': '#68e9a2'}}/>
             </Spin>
            }

            {/* <NavNotification />
            <NavLanguage /> */}
            
            <NavProfile />
            <NavPanel direction={direction} />
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose}/> */}
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { balance, navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } =  theme;
  return { balance, navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

const mapDispatchToProps = {
  getBalance,
	updateBalance,
  toggleCollapsedNav,
  onMobileNavToggle
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);