import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { BASEURL,SITEURL } from '../../BaseURL';
import { Link } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';

const menuItem = [
	{
		title: "Edit Profile",
		icon: EditOutlined ,
		path: "/app/settings/edit-profile/"
    },
    
    // {
		// title: "Account Setting",
		// icon: SettingOutlined,
		// path: "/"
    // },
    {
		title: "Billing",
		icon: ShopOutlined ,
		path: "/app/billing/"
	},
    {
		title: "Help Center",
		icon: QuestionCircleOutlined,
		path: "/app/support/"
	}
]

export const NavProfile = ({signOut}) => {
  const profileImg = BASEURL+"uploads/avatar/"+localStorage.getItem('avatar');
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} icon={<UserOutlined />} />
          <div className="pl-3">
            <h4 className="mb-0">{localStorage.getItem('name')}</h4>
            <span className="text-muted">{localStorage.getItem('userName')}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} icon={<UserOutlined />} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
