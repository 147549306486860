// export const SITEURL = 'http://localhost:3000/'
// export const BASEURL = 'http://localhost:5000/'

export const SITEURL = 'https://panel.didbiz.com/'
export const BASEURL = 'https://request.didbiz.com/'

//Testing
// export const RECAPTCHATOKEN =  '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
// live
export const RECAPTCHATOKEN =  '6LcTsWMfAAAAAJONvbXT7c5UuDghb-NSISQ-ybXa'

// export const RECAPTCHASECRET =  '6LcTsWMfAAAAAAFOB5E7AjrmmO2B2tGSG04z8ar2'


// Inteliquent API
export const APISANDBOXURL =  'https://services-sandbox.inteliquent.com'
export const APIBASEURL =  'https://services.inteliquent.com'

