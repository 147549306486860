import { 
  DashboardOutlined, 
  FileTextOutlined,
  DotChartOutlined,
  MailOutlined,
  LayoutOutlined,
  UploadOutlined, 
  PhoneOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { BASEURL } from '../BaseURL';

var menudata = [];
const getMenu = async() => {
  
  const myHeaders = new Headers();
  const token = localStorage.getItem('usertoken'); 
  // console.log(token);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', token);           


  let userID = localStorage.getItem('userID');
  const res = await fetch(BASEURL+'getmenu', {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({'userID':userID})   
  });
  
  const data = await res.json();
  console.log(data);
  // console.log(data.menu);return false;
  if(data.status !== 422 || !data){
    // setmenu(data.menu);
    // setsubmenu(data.submenu);
    
    localStorage.setItem('menu',JSON.stringify(data));
    for (let index = 0; index < data.menu.length; index++) {
      var obj = {};
      const element = data.menu[index];     
      // console.log(element.icon);
      let icon = element.icon;
      if(icon === "DashboardOutlined"){ icon = DashboardOutlined; }
      if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
      
      obj["key"] = element.key;
      obj["path"] = `${APP_PREFIX_PATH}/${element.url}`;
      obj["icon"] = icon;
      obj["breadcrumb"] = false;
      obj["submenu"] = [];
      for (let index1 = 0; index1 < data.submenu.length; index1++) {
          var obj1 = {};
          const element1 = data.submenu[index1];
          let icon = element1.icon;
          if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
          if(icon === "DotChartOutlined"){ icon = DotChartOutlined; }
          if(icon === "LayoutOutlined"){ icon = LayoutOutlined; }
          if(icon === "UploadOutlined"){ icon = UploadOutlined; }
          if(icon === "PhoneOutlined"){ icon = PhoneOutlined; }

          if(element1.menu_id == element.id){
            obj1["key"] = element1.key;
            obj1["path"] = `${APP_PREFIX_PATH}/${element1.url}`;
            obj1["title"] = element1.title;
            obj1["icon"] = icon;
            obj1["breadcrumb"] = false;
            obj1["submenu"] = [];
            obj["submenu"].push(obj1); 
            
            for (let index2 = 0; index2 < data.subsubrow.length; index2++) {  
              var obj2 = {};
              const element2 = data.subsubrow[index2];
              // console.log(data.userdata[0].is_sip_trunk);
              if(element2.menu_id == element1.id){
                if(element2.id == 4){
                  if(data.userdata && data.userdata[0].is_sip_trunk == 0){
                    continue;
                  } 
                }                
                obj2["key"] = element2.key;
                obj2["path"] = `${APP_PREFIX_PATH}/${element2.url}`;
                obj2["title"] = element2.title;
                // obj2["icon"] = icon;
                obj2["breadcrumb"] = false;
                obj2["submenu"] = [];
                obj1["submenu"].push(obj2)
              }
            }
          }         
      }
      obj["title"] = element.title;
      menudata.push(obj);      
    }  
   
  }	
  
}

if(localStorage.getItem('menu')){
  let data = JSON.parse(localStorage.getItem('menu'));
//  console.log(data);

//errorr occur here data.menu.length so change to data.length

  for (let index = 0; index < data.length; index++) {
    var obj = {};
    const element = data.menu[index];     
    // console.log(element.icon);
    let icon = element.icon;
    if(icon === "DashboardOutlined"){ icon = DashboardOutlined; }
    if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
    
    obj["key"] = element.key;
    obj["path"] = `${APP_PREFIX_PATH}/${element.url}`;
    obj["icon"] = icon;
    obj["breadcrumb"] = false;
    obj["submenu"] = [];
    for (let index1 = 0; index1 < data.submenu.length; index1++) {
        var obj1 = {};
        const element1 = data.submenu[index1];
        let icon = element1.icon;
        if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
        if(icon === "DotChartOutlined"){ icon = DotChartOutlined; }
        if(icon === "LayoutOutlined"){ icon = LayoutOutlined; }
        if(icon === "UploadOutlined"){ icon = UploadOutlined; }
        if(icon === "PhoneOutlined"){ icon = PhoneOutlined; }

        if(element1.menu_id == element.id){
          obj1["key"] = element1.key;
          obj1["path"] = `${APP_PREFIX_PATH}/${element1.url}`;
          obj1["title"] = element1.title;
          obj1["icon"] = icon;
          obj1["breadcrumb"] = false;
          obj1["submenu"] = [];
          obj["submenu"].push(obj1); 
          
          for (let index2 = 0; index2 < data.subsubrow.length; index2++) {  
            var obj2 = {};
            const element2 = data.subsubrow[index2];
            // console.log(data.userdata[0].is_sip_trunk);
            if(element2.menu_id == element1.id){
              if(element2.id == 4){
                // console.log(data.userdata);
                if(data.userdata && data.userdata[0].is_sip_trunk == 0){
                  continue;
                } 
              }                
              obj2["key"] = element2.key;
              obj2["path"] = `${APP_PREFIX_PATH}/${element2.url}`;
              obj2["title"] = element2.title;
              // obj2["icon"] = icon;
              obj2["breadcrumb"] = false;
              obj2["submenu"] = [];
              obj1["submenu"].push(obj2)
            }
          }
        }         
    }
    obj["title"] = element.title;
    menudata.push(obj);      
  }  
    
  // console.log(JSON.parse(menu));
  // menudata = JSON.parse(menu);
}else{
  let token = localStorage.getItem('usertoken'); 
  if(token){
    getMenu();
  }
}
// var string = JSON.stringify(menudata);
// string.replace (/"/g,'');
// console.log(menudata);
// console.log(dashBoardNavTree);
// const getUserdata = async() => {
//   const myHeaders = new Headers();
//   myHeaders.append('Content-Type', 'application/json');
//   let userID = localStorage.getItem('userID');
//   const res = await fetch(BASEURL+'getProfileData/'+userID, {
//     method: "GET",
//     headers: myHeaders, 
//     // body: JSON.stringify(userID)   
//   });
  
//   const data = await res.json();
//   if(data.status == 201 || !data){
//       // console.log(data.data[0].is_sip_trunk);
//       var issip = data.data[0].is_sip_trunk
//       // console.log(issip);
//       localStorage.setItem('issip', issip);
//       // menudata = dashBoardNavTree;
    
//   }	
// }


// getUserdata();
// var issip = localStorage.getItem('issip');
// console.log(issip);  
// if(issip!==undefined){
  
//   if(issip == 1){
//     menudata = menudata.concat(dashBoardNavTree);
//   }
//   if(issip == 0){
//     menudata = menudata.concat(dashBoardNavTree1);
//   }
  
// }
// console.log(menudata);


// let role = localStorage.getItem('role');
// if(role == 0 || role == 1){
  
  
  // console.log(dashBoardNavTree);
// }else if(role == 2){
//   dashBoardNavTree = [
//   {
//     key: 'SupportDashboard',
//     path: `${APP_PREFIX_PATH}/support-dashboard`,
//     title: 'Dashboard',
//     icon: DashboardOutlined,
//     breadcrumb: false,
//     submenu: [   
//     ]
//   },
//   {
//     key: 'Support',
//     path: `${APP_PREFIX_PATH}/support`,
//     title: 'Support',
//     icon: DashboardOutlined,
//     breadcrumb: false,
//     submenu: [   
//     ]
//   },
// ]; 
// }




// var dashBoardNavTree = [
//   {
//   key: 'dashboards',
//   path: `${APP_PREFIX_PATH}/dashboards`,
//   title: 'sidenav.dashboard',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: [  
//   ]
// },
//   {
//   key: 'voice', 
//   title: 'Voice',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: [
//     {
//       key: 'logs',
//       title: 'Logs',
//       icon: FileTextOutlined,
//       breadcrumb: false,
//       submenu: [{
//         key: 'detailcalls',
//         path: `${APP_PREFIX_PATH}/voice/detail-calls`,
//         title: 'Detail Call',
//         breadcrumb: false,
//         submenu: []
//       },     
//       {
//         key: 'summary',
//         path: `${APP_PREFIX_PATH}/voice/summary`,
//         title: 'Summary',
//         breadcrumb: false,
//         submenu: []
//       },]
//     }, 
//     {
//       key: 'overview',
//       path: `${APP_PREFIX_PATH}/voice/overview`,
//       title: 'Overview',
//       icon: DotChartOutlined,
//       breadcrumb: false,
//       submenu: []
//     }, 
//   ]
// },

// {
//   key: 'messaging',
//   title: 'Messaging',
//   breadcrumb: false,
//   submenu: [
//     {
//       key: 'messagingOverview',
//       path: `${APP_PREFIX_PATH}/messaging/overview`,
//       title: 'Overview',
//       icon: DotChartOutlined,
//       breadcrumb: false,
//       submenu: []
//     }, 
  
//   ]
// },

//   {
//     key: 'apps-mail',
//     title: 'Trunk',
//     breadcrumb: false,
//     submenu: [{
//       key: 'TrunkOverview',
//       path: `${APP_PREFIX_PATH}/trunk/overview`,
//       title: 'Overview',
//       icon: DotChartOutlined,
//       breadcrumb: false,
//       submenu: []
//     },{
//       key: 'TrunkInbound',
//       path: `${APP_PREFIX_PATH}/trunk/inbound`,
//       title: 'Inbound',
//       icon: LayoutOutlined,
//       breadcrumb: false,
//       submenu: []
//     },{
//       key: 'TrunkOutbound',
//       path: `${APP_PREFIX_PATH}/trunk/outbound`,
//       title: 'Outbound',
//       icon: UploadOutlined,
//       breadcrumb: false,
//       submenu: [{
//         key: 'IPAccessControl',
//         path: `${APP_PREFIX_PATH}/trunk/outbound/IPAccessControl`,
//         title: 'IP Access Control',
//         breadcrumb: false,
//         submenu: []
//       },{
//         key: 'SIPtrunk',
//         path: `${APP_PREFIX_PATH}/trunk/outbound/SIP-trunk`,
//         title: 'SIP Trunk',
//         breadcrumb: false,
//         submenu: []
//       }]
//     },]
//   }, 

//   {
//     key: 'PhoneNumber',
//     title: 'Phone Number',
//     icon: MailOutlined,
//     breadcrumb: false,
//     submenu: [{
//       key: 'PhoneNumberOverview',
//       path: `${APP_PREFIX_PATH}/numbers/overview`,
//       title: 'Overview',
//       icon: PhoneOutlined,
//       breadcrumb: false,
//       submenu: [
//         {
//           key: 'PhoneNumberOverview',
//           path: `${APP_PREFIX_PATH}/numbers/listing`,
//           title: 'Numbers',
//           breadcrumb: false,
//           submenu: []
//         }
//       ]
//     },    
//   ]
//   }, 
  
//   {
//     key: 'Support',
//     path: `${APP_PREFIX_PATH}/support`,
//     title: 'Support',
//     icon: DashboardOutlined,
//     breadcrumb: false,
//     submenu: [  
//     ]
//   },
//   {
//     key: 'Billing',
//     path: `${APP_PREFIX_PATH}/billing`,
//     title: 'Billing',
//     icon: FileTextOutlined,
//     breadcrumb: false,
//     submenu: [  
//     ]
//   },

// ]



export default menudata;
