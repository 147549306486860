import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
// import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";

// For Navigation
import { 
  DashboardOutlined, 
  FileTextOutlined,
  DotChartOutlined,
  MailOutlined,
  LayoutOutlined,
  UploadOutlined, 
  PhoneOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { BASEURL } from '../../BaseURL';


const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
	const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const [navigationConfig, setnavigationConfig] = useState([])
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}

  useEffect(() => {
    getMenu()
  }, [])

  var menudata = [];
  const getMenu = async() => {
    var data;
    // if(localStorage.getItem('menu')){
    //    data = JSON.parse(localStorage.getItem('menu'));
    // }else{
      const myHeaders = new Headers();
      const token = localStorage.getItem('usertoken'); 
      // console.log(token);
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', token);           
    
      let userID = localStorage.getItem('userID');
      const res = await fetch(BASEURL+'getmenu', {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({'userID':userID})   
      });
      
       data = await res.json();
    // }
    // console.log(data.menu);return false;
    if(data.status != 422 || !data){
      // setmenu(data.menu);
      // setsubmenu(data.submenu);
      localStorage.setItem('menu',JSON.stringify(data));
      for (let index = 0; index < data.menu.length; index++) {
        var obj = {};
        const element = data.menu[index];     
        // console.log(element.icon);
        let icon = element.icon;
        if(icon === "DashboardOutlined"){ icon = DashboardOutlined; }
        if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
        
        obj["key"] = element.key;
        obj["path"] = `${APP_PREFIX_PATH}/${element.url}`;
        obj["icon"] = icon;
        obj["breadcrumb"] = false;
        obj["submenu"] = [];
        for (let index1 = 0; index1 < data.submenu.length; index1++) {
            var obj1 = {};
            const element1 = data.submenu[index1];
            let icon = element1.icon;
            if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
            if(icon === "DotChartOutlined"){ icon = DotChartOutlined; }
            if(icon === "LayoutOutlined"){ icon = LayoutOutlined; }
            if(icon === "UploadOutlined"){ icon = UploadOutlined; }
            if(icon === "PhoneOutlined"){ icon = PhoneOutlined; }
  
            if(element1.menu_id == element.id){
              obj1["key"] = element1.key;
              obj1["path"] = `${APP_PREFIX_PATH}/${element1.url}`;
              obj1["title"] = element1.title;
              obj1["icon"] = icon;
              obj1["breadcrumb"] = false;
              obj1["submenu"] = [];
              obj["submenu"].push(obj1); 
              
              for (let index2 = 0; index2 < data.subsubrow.length; index2++) {  
                var obj2 = {};
                const element2 = data.subsubrow[index2];
                // console.log(data.userdata[0].is_sip_trunk);
                if(element2.menu_id == element1.id){
                  if(element2.id == 4){
                    if(data.userdata && data.userdata[0].is_sip_trunk == 0){
                      continue;
                    } 
                  }                
                  obj2["key"] = element2.key;
                  obj2["path"] = `${APP_PREFIX_PATH}/${element2.url}`;
                  obj2["title"] = element2.title;
                  // obj2["icon"] = icon;
                  obj2["breadcrumb"] = false;
                  obj2["submenu"] = [];
                  obj1["submenu"].push(obj2)
                }
              }
            }         
        }
        obj["title"] = element.title;
        menudata.push(obj);      
      }       
    }	    
    setnavigationConfig(menudata);
  }
  
  return (    
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={setLocale(localization, menu.title)}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? (
                        <Icon type={subMenuSecond?.icon} />
                      ) : null}
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  const [navigationConfig, setnavigationConfig] = useState([])
  
  useEffect(() => {
    getMenu()
  }, [])

  var menudata = [];
  const getMenu = async() => {
    var data;
    // if(localStorage.getItem('menu')){
    //    data = JSON.parse(localStorage.getItem('menu'));
    // }else{
      const myHeaders = new Headers();
      const token = localStorage.getItem('usertoken'); 
      // console.log(token);
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', token);           
    
      let userID = localStorage.getItem('userID');
      const res = await fetch(BASEURL+'getmenu', {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({'userID':userID})   
      });
      
       data = await res.json();
    // }
    // console.log(data.menu);return false;
    if(data.status != 422 || !data){
      // setmenu(data.menu);
      // setsubmenu(data.submenu);
      localStorage.setItem('menu',JSON.stringify(data));
      for (let index = 0; index < data.menu.length; index++) {
        var obj = {};
        const element = data.menu[index];     
        // console.log(element.icon);
        let icon = element.icon;
        if(icon === "DashboardOutlined"){ icon = DashboardOutlined; }
        if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
        
        obj["key"] = element.key;
        obj["path"] = `${APP_PREFIX_PATH}/${element.url}`;
        obj["icon"] = icon;
        obj["breadcrumb"] = false;
        obj["submenu"] = [];
        for (let index1 = 0; index1 < data.submenu.length; index1++) {
            var obj1 = {};
            const element1 = data.submenu[index1];
            let icon = element1.icon;
            if(icon === "FileTextOutlined"){ icon = FileTextOutlined; }
            if(icon === "DotChartOutlined"){ icon = DotChartOutlined; }
            if(icon === "LayoutOutlined"){ icon = LayoutOutlined; }
            if(icon === "UploadOutlined"){ icon = UploadOutlined; }
            if(icon === "PhoneOutlined"){ icon = PhoneOutlined; }
  
            if(element1.menu_id == element.id){
              obj1["key"] = element1.key;
              obj1["path"] = `${APP_PREFIX_PATH}/${element1.url}`;
              obj1["title"] = element1.title;
              obj1["icon"] = icon;
              obj1["breadcrumb"] = false;
              obj1["submenu"] = [];
              obj["submenu"].push(obj1); 
              
              for (let index2 = 0; index2 < data.subsubrow.length; index2++) {  
                var obj2 = {};
                const element2 = data.subsubrow[index2];
                // console.log(data.userdata[0].is_sip_trunk);
                if(element2.menu_id == element1.id){
                  if(element2.id == 4){
                    if(data.userdata && data.userdata[0].is_sip_trunk == 0){
                      continue;
                    } 
                  }                
                  obj2["key"] = element2.key;
                  obj2["path"] = `${APP_PREFIX_PATH}/${element2.url}`;
                  obj2["title"] = element2.title;
                  // obj2["icon"] = icon;
                  obj2["breadcrumb"] = false;
                  obj2["submenu"] = [];
                  obj1["submenu"].push(obj2)
                }
              }
            }         
        }
        obj["title"] = element.title;
        menudata.push(obj);      
      }       
    }	    
    setnavigationConfig(menudata);
  }

  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
